<template>
  <b-card class="border-0">
    <div>
      <b-row>
        <b-col md="6">
          <p>
            <strong>Data Creazione</strong>
            <br />
            {{userCreatedAt}}
          </p>
        </b-col>
        <b-col md="6">
          <p>
            <strong>Ultima modifica</strong>
            <br />
            {{userLastModified}}
          </p>
        </b-col>
        <b-col md="6">
          <p>
            <strong>Status</strong>
            <br />
            <badge
              :status="userReport.status"
              statuses="report"
            />
          </p>
        </b-col>
        <b-col
          v-if="isOwner"
          md="6"
        >
          <p>
            <strong>Soggetto valutato</strong>
            <br />
            {{referentData}}
          </p>
        </b-col>
        <b-col
          v-else
          md="6"
        >
          <p>
            <strong>Generato da</strong>
            <br />
            {{reporterData}}
          </p>
        </b-col>
        <b-col md="12">
          <p>
            <strong>Richiesta</strong>
            <br>
            <em>{{userReport.labelFeedbackType}}</em>
            <br>
            {{userReport.labelFeedbackQuestion}}
          </p>
        </b-col>
        <b-col md="12">
          <p class="overflow-protected-field">
            <strong>Descrizione segnalazione</strong>
            <br>
            <span v-if="checkNoteFeedback">
              {{userReport.labelFeedbackDetail}}
              <br v-if="checkFeedback && checkNote">
              {{userReport.note}}
            </span>
            <span v-else>
              {{notAvailableMsg}}
            </span>
          </p>
        </b-col>
      </b-row>
    </div>
    <div class="mt-3 pt-3 px-3 border-top">
      <document-list
        :entity-id="userReport.reportName"
        :entity-class="entityClass"
        :documents="documents"
        slim
        genericListTitle="Documenti aggiuntivi"
        isGenericList
      />
    </div>
    <new-doc-upload
      v-if="canAddDocument"
      :entity-id="userReport.reportName"
      :entity-class="entityClass"
      :document-entity-type="documentEntityType"
      :on-success="onSuccessAdd"
    />
    <b-card
      class="mt-3"
      body-class="bg-gray-200 border-0 text-right p-2"
      v-if="validateButtons(['CONFIRMED', 'LITIGATION'], true)"
    >
      <b-button-group size="sm">
        <b-button
          variant="danger"
          @click="openStatusModal('ABORTED')"
        >
          ANNULLA
        </b-button>
        <b-button
          variant="danger"
          @click="openInfoModal('ABORTED')"
          class="btn-contrasted"
        >
          <font-awesome-icon icon="info-circle" class="fa-fw"></font-awesome-icon>
        </b-button>
      </b-button-group>
      <b-button-group size="sm">
        <b-button
          variant="dark"
          class="ms-2"
          @click="openStatusModal('USER_CLOSED')"
        >
          CHIUDI
        </b-button>
        <b-button
          variant="dark"
          @click="openInfoModal('USER_CLOSED')"
          class="btn-contrasted"
        >
          <font-awesome-icon icon="info-circle" class="fa-fw"></font-awesome-icon>
        </b-button>
      </b-button-group>
    </b-card>
    <b-card
      class="mt-3"
      body-class="bg-gray-200 border-0 text-right p-2"
      v-if="validateButtons(['CONFIRMED'], false)"
    >
      <b-button-group size="sm">
        <b-button
          variant="danger"
          @click="openStatusModal('LITIGATION', responseContextTableName)"
        >
          CONTESTA
        </b-button>
        <b-button
          variant="danger"
          @click="openInfoModal('LITIGATION')"
          class="btn-contrasted"
        >
          <font-awesome-icon icon="info-circle" class="fa-fw"></font-awesome-icon>
        </b-button>
      </b-button-group>
    </b-card>
    <modal-status-change
      id="userReportStatusChange"
      :state="statusChangeValue"
      :reportName="userReport.reportName"
      :userReportId="userReport.userReportId"
      :response-context="responseContext"
      :is-owner="isOwner"
    />
    <modal-status-info
      id="userReportStatusInfo"
      :state="statusChangeValue"
    />
  </b-card>
</template>

<script>
import { USER_REPORT_CLASS } from '@/utils/interfaces';
import { isNotEmpty, isNotExist, isPresent } from '@/utils/validators';
import { formatDateTime } from '@/utils/formatterHelper';

const NewDocUpload = () => import('@/components/userSections/NewDocUpload.vue');
const DocumentList = () => import('@/components/documents/DocumentList.vue');
const ModalStatusChange = () => import('@/components/ModalStatusChange.vue');
const ModalStatusInfo = () => import('@/components/userReports/ModalStatusInfo.vue');
const Badge = () => import('@/components/helpers/Badge.vue');

export default {
  name: 'UserReportDetailInfo',
  components: {
    DocumentList,
    NewDocUpload,
    ModalStatusChange,
    ModalStatusInfo,
    Badge,
  },
  props: {
    userReport: { ...USER_REPORT_CLASS },
  },
  data() {
    return {
      entityClass: 'USERREPORT',
      documentEntityType: 'USERREPORT',
      statusChangeValue: '',
      responseContext: '',
    };
  },
  computed: {
    documents() {
      return this.$store.getters['documents/getDocumentList'](this.entityClass, this.userReport.reportName);
    },
    notAvailableMsg() {
      return 'N.D.';
    },
    referentToPerson() {
      return isPresent(this.userReport.referentInfo.personId);
    },
    referentData() {
      if (this.referentToPerson) {
        return `${this.userReport.referentInfo.name} ${this.userReport.referentInfo.surname} (${this.userReport.referentInfo.taxCode})`;
      }
      return `${this.userReport.referentInfo.companyName} (${this.userReport.referentInfo.vatNumber})`;
    },
    reporterToPerson() {
      return isPresent(this.userReport.reporterInfo.personId);
    },
    reporterData() {
      if (this.reporterToPerson) {
        return `${this.userReport.reporterInfo.name} ${this.userReport.reporterInfo.surname} (${this.userReport.reporterInfo.taxCode})`;
      }
      return `${this.userReport.reporterInfo.companyName} (${this.userReport.reporterInfo.vatNumber})`;
    },
    userCreatedAt() {
      return formatDateTime(this.userReport.createdAt);
    },
    userLastModified() {
      return formatDateTime(this.userReport.lastModified);
    },
    headingText() {
      return `Dettaglio Segnalazione: ${this.userReport.reportName}`;
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    isOwner() {
      if (this.isCompanyProfile) {
        return this.userReport.reporterInfo.companyId === this.$store.getters['subject/currentCompany'].companyId;
      }
      return this.userReport.reporterInfo.personId === this.$store.getters['subject/currentPerson'].personId;
    },
    canAddDocument() {
      const validState = ['CONFIRMED', 'LITIGATION'];
      return validState.indexOf(this.userReport.status) !== -1;
    },
    responseContextTableName() {
      if (this.isOwner) {
        return this.userReport.feedbackQuestion;
      }
      return `report_response_${this.userReport.feedbackQuestion}`;
    },
    checkNote() {
      return isNotEmpty(this.userReport.note);
    },
    checkFeedback() {
      return isNotEmpty(this.userReport.labelFeedbackDetail);
    },
    checkNoteFeedback() {
      return this.checkFeedback || this.checkNote;
    },
  },
  mounted() {
    this.loadDocuments();
  },
  methods: {
    loadDocuments() {
      console.debug('Load documents', this.userReport);
      if (isNotExist(this.userReport)) return;

      this.$store.dispatch('documents/loadDocuments', { entityClass: this.entityClass, entityId: this.userReport.reportName });
    },
    onSuccessAdd(data) {
      this.$store.dispatch('documents/setDocuments', {
        entityClass: this.entityClass,
        entityId: this.userReport.reportName,
        docs: data,
      });
    },
    validateButtons(statuses, owner) {
      return statuses.indexOf(this.userReport.status) !== -1 && this.isOwner === owner;
    },
    openStatusModal(state, responseContext) {
      this.statusChangeValue = state;
      this.responseContext = responseContext;
      this.$bvModal.show('userReportStatusChange');
    },
    openInfoModal(state) {
      this.statusChangeValue = state;
      this.$bvModal.show('userReportStatusInfo');
    },
  },
};
</script>

<style scoped>

</style>
